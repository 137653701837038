export const favoriteSteps = [
  {
    selector: ".create-button",
    content: "Create new folder",
  },
  {
    selector: ".check-box",
    content: "Click here to select a listing",
  },
  {
    selector: ".tag-analyzer",
    content: "Analyze the search volume for every tag for these listings.",
  },
];

export const productAnalyticsSteps = [
  {
    selector: ".analyze-by-term",
    content: "Analyze by search term",
  },
  {
    selector: ".sort-by-revenue",
    content: "Sort by revenue",
  },

  {
    selector: ".fav-star",
    content: "Add to Favorites",
  },
  {
    selector: ".tag-analyzer",
    content: "Analyze the search volume for every tag for these listings",
  },
  {
    selector: ".analyze-by-shop",
    content: "Search a shop and analyze you competition",
  },
];

export const dashboardStepsNew = [
  {
    selector: ".sales-channels",
    content: "View your connected shops and add more",
  },
  {
    selector: ".time-range",
    content: "Select your time frame and currency",
  },
  {
    selector: ".shop-selector",
    content: "Select your shop",
  },
  {
    selector: ".extension",
    content: "Install the extension",
  },
];
