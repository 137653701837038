const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getNDays(X) {
  var dates = [];
  for (let i = 0; i < X; i++) {
    var date = new Date();
    var thatDay = date.getDate() - i;
    date.setDate(thatDay);
    let day = "0" + date.getDate().toString();
    let month = date.getMonth() + 1;
    let year = date.getFullYear().toString();
    dates.push(months[month - 1] + " " + day.substr(-2) + ", " + year);
  }
  return dates;
}

export function getNMonths(type, startdate) {
  var startDate;
  var endDate;
  var date = new Date();
  if (type == "This Year") {
    startDate = months[0] + ", " + date.getFullYear();
    endDate = months[date.getMonth()] + ", " + date.getFullYear();
  } else if (type == "Last Year") {
    startDate = months[0] + ", " + (date.getFullYear() - 1);
    endDate = months[11] + ", " + (date.getFullYear() - 1);
  } else if (type == "All Time") {
    startDate = startdate;
    endDate = months[date.getMonth()] + ", " + date.getFullYear();
  } else {
  }
  var start = startDate.split(", ");
  var end = endDate.split(", ");
  var startYear = parseInt(start[1]);
  var endYear = parseInt(end[1]);
  var dates = [];
  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : months.indexOf(end[0]);
    var startMon = i === startYear ? months.indexOf(start[0]) : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push(months[displayMonth - 1] + ", " + i);
    }
  }
  return dates;
}
