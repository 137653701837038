import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import { ContactlessOutlined, TrendingUpRounded } from "@mui/icons-material";
import { useTheme } from "../../contexts/ColorModeContext";
import { makeStyles } from "@mui/styles";
import { getNumberWithCommas } from "../../helper/getNumberWithCommas";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import { useDashboard } from "../../contexts/DashboardContext";
import { getNDays, getNMonths } from "../../helper/dateRangeArray";
import { options } from "amplitude-js";
import NoData from "./NoData";
import ConnectShopMessage from "./ConnectShopMessage";

const useStyles = makeStyles(
  (theme) => ({
    graphWrapper: {
      height: "400px",
      width: "100%",
      paddingRight: "32px",
      "&.MuiPaper-root": {
        borderRadius: "20px",
      },

      [theme.breakpoints.down("sm")]: {
        height: "380px",
        marginRight: "10px",
        paddingRight: "10px",
        "&.MuiPaper-root": {
          borderRadius: "9px",
        },
      },
    },
    graphInfo: {
      display: "flex",
      alignItems: "center",
      gap: "3px",
      mt: "12px",
      [theme.breakpoints.down("sm")]: {
        mt: "5px",
      },
    },
    graph: {
      height: "80%",
      width: "100%",
      padding: "5px",
    },
  }),
  {
    name: "MuiCustomGraphs",
  }
);

const GraphComponent = ({ data, currencySymbol, isLoading }) => {
  const { option, setOption } = useDashboard();
  const { salesChannelsState } = useSalesChannel();
  const [dateArray, setDateArray] = useState([]);
  function getDateArray() {
    if (salesChannelsState?.["timeRangeSelector"] == "Today") {
      var date = getNDays(1);
      setDateArray(date);
    } else if (salesChannelsState?.["timeRangeSelector"] == "Yesterday") {
      var date = getNDays(2);
      setDateArray(date.slice(1, 2));
    } else if (salesChannelsState?.["timeRangeSelector"] == "Last 7 Days") {
      var date = getNDays(7);
      setDateArray(date.reverse());
    } else if (salesChannelsState?.["timeRangeSelector"] == "Last 30 Days") {
      var date = getNDays(30);
      setDateArray(date.reverse());
    } else if (salesChannelsState?.["timeRangeSelector"] == "This Month") {
      var date = getNDays(30);
      setDateArray(date.slice(0, new Date().getDate()).reverse());
    } else if (salesChannelsState?.["timeRangeSelector"] == "This Year") {
      var date = getNMonths("This Year");
      setDateArray(date);
    } else if (salesChannelsState?.["timeRangeSelector"] == "Last Year") {
      var date = getNMonths("Last Year");
      setDateArray(date);
    } else if (
      data != undefined &&
      salesChannelsState?.["timeRangeSelector"] == "All Time"
    ) {
      const graphData = Object.entries(data);
      if (Object.keys(data).length != 0) {
        var date = graphData.map((item) => item && item[0]);
        setDateArray(date);
      }
    } else {
      setDateArray([]);
    }
  }

  useEffect(() => {
    getDateArray();
  }, [data]);

  if (data) {
    const graphData = Object?.entries(data);
    var graphDateArray = [];
    for (var i = 0; i < graphData?.length; i++) {
      graphDateArray.push(graphData[i][0]);
    }
    var graphD = [];
    for (var i = 0; i < dateArray?.length; i++) {
      if (graphDateArray.indexOf(dateArray[i]) !== -1) {
        const obj = {
          date: graphData[graphDateArray.indexOf(dateArray[i])][0],
          average_order_value:
            graphData[graphDateArray.indexOf(dateArray[i])][1]
              .average_order_value,
          gross_revenue:
            graphData[graphDateArray.indexOf(dateArray[i])][1].gross_revenue,
          ltv: graphData[graphDateArray.indexOf(dateArray[i])][1].ltv,
          purchase_frequency:
            graphData[graphDateArray.indexOf(dateArray[i])][1]
              .purchase_frequency,
          repeat_customer_rate:
            graphData[graphDateArray.indexOf(dateArray[i])][1]
              .repeat_customer_rate,
          total_orders:
            graphData[graphDateArray.indexOf(dateArray[i])][1].total_orders,
          uniq_buyers:
            graphData[graphDateArray.indexOf(dateArray[i])][1].uniq_buyers,
        };
        graphD.push(obj);
      } else {
        const obj = {
          date: dateArray[i],
          average_order_value: 0,
          gross_revenue: 0,
          ltv: 0,
          purchase_frequency: 0,
          repeat_customer_rate: 0,
          total_orders: 0,
          uniq_buyers: 0,
        };
        graphD.push(obj);
      }
    }
  }

  const generateGraphdata = (graphD) => {
    if (
      salesChannelsState?.["timeRangeSelector"] == "Last 7 Days" ||
      salesChannelsState?.["timeRangeSelector"] == "Last 30 Days" ||
      salesChannelsState?.["timeRangeSelector"] == "This Month"
    ) {
      const newGraphData = [];
      let avg_aov = 0;
      let avg_gr = 0;
      let avg_ltv = 0;
      let avg_pf = 0;
      let avg_rcr = 0;
      let avg_to = 0;
      let avg_ub = 0;
      for (let i = 0; i < graphD?.length; i++) {
        if (i < graphD?.length - 1) {
          avg_aov += graphD[i]["average_order_value"];
          avg_gr += graphD[i]["gross_revenue"];
          avg_ltv += graphD[i]["ltv"];
          avg_pf += graphD[i]["purchase_frequency"];
          avg_rcr += graphD[i]["repeat_customer_rate"];
          avg_to += graphD[i]["total_orders"];
          avg_ub += graphD[i]["uniq_buyers"];
        }
        if (i < graphD?.length - 2) {
          newGraphData.push(graphD[i]);
        } else if (i === graphD?.length - 2) {
          newGraphData.push({
            average_order_value:
              graphD[i]["average_order_value"] === 0
                ? Math.round(avg_aov / (graphD?.length - 1))
                : graphD[i]["average_order_value"],
            gross_revenue:
              graphD[i]["gross_revenue"] === 0
                ? Math.round(avg_gr / (graphD?.length - 1))
                : graphD[i]["gross_revenue"],
            ltv:
              graphD[i]["ltv"] === 0
                ? Math.round(avg_ltv / (graphD?.length - 1))
                : graphD[i]["ltv"],
            purchase_frequency:
              graphD[i]["purchase_frequency"] === 0
                ? Math.random(avg_pf / (graphD?.length - 1))
                : graphD[i]["purchase_frequency"],
            repeat_customer_rate:
              graphD[i]["repeat_customer_rate"] === 0
                ? Math.round(avg_rcr / (graphD?.length - 1))
                : graphD[i]["repeat_customer_rate"],
            total_orders:
              graphD[i]["total_orders"] === 0
                ? Math.round(avg_to / (graphD?.length - 1))
                : graphD[i]["total_orders"],
            uniq_buyers:
              graphD[i]["uniq_buyers"] === 0
                ? Math.round(avg_ub / (graphD?.length - 1))
                : graphD[i]["uniq_buyers"],
            date: graphD[i]["date"],
            predicted_average_order_value:
              graphD[i]["average_order_value"] === 0
                ? Math.round(avg_aov / (graphD?.length - 1))
                : graphD[i]["average_order_value"],
            predicted_gross_revenue:
              graphD[i]["gross_revenue"] === 0
                ? Math.round(avg_gr / (graphD?.length - 1))
                : graphD[i]["gross_revenue"],
            predicted_ltv:
              graphD[i]["ltv"] === 0
                ? Math.round(avg_ltv / (graphD?.length - 1))
                : graphD[i]["ltv"],
            predicted_purchase_frequency:
              graphD[i]["purchase_frequency"] === 0
                ? Math.random(avg_pf / (graphD?.length - 1))
                : graphD[i]["purchase_frequency"],
            predicted_repeat_customer_rate:
              graphD[i]["repeat_customer_rate"] === 0
                ? Math.round(avg_rcr / (graphD?.length - 1))
                : graphD[i]["repeat_customer_rate"],
            predicted_total_orders:
              graphD[i]["total_orders"] === 0
                ? Math.round(avg_to / (graphD?.length - 1))
                : graphD[i]["total_orders"],
            predicted_uniq_buyers:
              graphD[i]["uniq_buyers"] === 0
                ? Math.round(avg_ub / (graphD?.length - 1))
                : graphD[i]["uniq_buyers"],
          });
        } else {
          newGraphData.push({
            date: graphD[i]["date"],
            predicted_average_order_value: Math.round(
              avg_aov / (graphD?.length - 1)
            ),
            predicted_gross_revenue: Math.round(avg_gr / (graphD?.length - 1)),
            predicted_ltv: Math.round(avg_ltv / (graphD?.length - 1)),
            predicted_total_orders: Math.round(avg_to / (graphD?.length - 1)),
            predicted_purchase_frequency: Math.random(
              avg_pf / (graphD?.length - 1)
            ),
            predicted_repeat_customer_rate: Math.round(
              avg_rcr / (graphD?.length - 1)
            ),
            predicted_uniq_buyers: avg_ub / (graphD?.length - 1),
          });
        }
      }
      return newGraphData;
    } else return graphD;
  };

  const predictedOption = () => {
    if (option === "gross_revenue") return "predicted_gross_revenue";
    else if (option === "average_order_value")
      return "predicted_average_order_value";
    else if (option === "total_orders") return "predicted_total_orders";
    else if (option === "ltv") return "predicted_ltv";
  };

  const classes = useStyles();
  return (
    <>
      <Paper
        elevation={0}
        className={classes.graphWrapper}
        sx={{
          backgroundColor: "background.secondary",
        }}
      >
        <FormControl
          sx={{ margin: 2, marginBottom: 0, minWidth: 120 }}
          size="small"
        >
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={option}
            onChange={(event) => {
              setOption(event.target.value);
            }}
          >
            <MenuItem value="gross_revenue">Revenue</MenuItem>
            <MenuItem value="average_order_value">Avg. Order Value</MenuItem>
            <MenuItem value="total_orders">Total Orders</MenuItem>
            <MenuItem value="ltv">Lifetime Value</MenuItem>
          </Select>
        </FormControl>
        {!salesChannelsState?.sellerSalesChannelsDetails[0]
          ?.sales_channel_shop_name ? (
          <ConnectShopMessage />
        ) : generateGraphdata(graphD)?.length == 0 ? (
          <Box>
            <NoData startSelling={true} />
          </Box>
        ) : (
          <Box className={classes.graph}>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "80%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src="/loadingT.gif"
                  alt="loader"
                  sx={{ width: "100px" }}
                />
              </Box>
            ) : (
              <ResponsiveContainer height="100%" className="graph-responsive">
                <AreaChart
                  data={generateGraphdata(graphD)}
                  height="100%"
                  margin={{ top: 1, right: 0, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="0%"
                        stopColor="rgba(68, 169, 243, 1)"
                        stopOpacity={0.4}
                      />
                      <stop
                        offset="90%"
                        stopColor="rgba(68, 169, 243, 0)"
                        stopOpacity={0.05}
                      />
                    </linearGradient>
                  </defs>
                  <Tooltip
                    wrapperStyle={{ outline: "none" }}
                    content={<CustomTooltip currencySymbol={currencySymbol} />}
                  />
                  <Area
                    type="monotoneX"
                    dataKey={option}
                    stroke="#2178DA"
                    strokeWidth={1}
                    fillOpacity={1}
                    fill="url(#color)"
                  />
                  <Area
                    type="monotoneX"
                    dataKey={predictedOption()}
                    stroke="#2178DA"
                    strokeWidth={1}
                    fillOpacity={0}
                    fill="url(#color)"
                    strokeDasharray="5 5"
                  />
                  <XAxis dataKey="date" axisLine={false} tickLine={false} />
                  <YAxis
                    dataKey={option}
                    axisLine={false}
                    tickLine={false}
                    tickCount={4}
                    tickSize={0}
                    width={67}
                    tickFormatter={(number) =>
                      option !== "total_orders" &&
                      predictedOption !== "predicted_total_orders"
                        ? `${currencySymbol}${getNumberWithCommas(number)}`
                        : getNumberWithCommas(number)
                    }
                  />
                  <CartesianGrid
                    opacity={0.4}
                    vertical={false}
                    strokeDasharray="4"
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </Box>
        )}
      </Paper>
    </>
  );
};

export default GraphComponent;

function CustomTooltip({ active, payload, label, currencySymbol }) {
  const { mode } = useTheme();
  if (active) {
    return (
      <div
        className="tooltip"
        style={{
          backgroundColor: mode === "light" ? "#002753" : "#2178DA",
        }}
      >
        <Typography fontSize="12px" lineHeight="18px" fontWeight="600">
          {payload &&
          payload[0]?.dataKey !== "total_orders" &&
          payload[0]?.dataKey !== "predicted_total_orders" ? (
            <>
              <Typography
                component="span"
                fontSize="12px"
                fontFamily="sans-serif"
              >
                {currencySymbol}
              </Typography>{" "}
              {getNumberWithCommas(payload[0]?.value)}
            </>
          ) : (
            payload && getNumberWithCommas(payload[0]?.value)
          )}
        </Typography>
        <Typography
          fontSize="9px"
          lineHeight="13px"
          fontWeight="400"
          color="grayShades.secondary"
        >
          {label}
        </Typography>
      </div>
    );
  }
  return null;
}
